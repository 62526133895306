import cn from 'classnames';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import { CloseIcon } from '../../assets/svg/CloseIcon';
import { useKeyDownListener } from '../../hooks/useKeyDownListener';
import styles from './Modal.module.scss';
import { IModal } from './types/IModal';
import { useModalVisibility } from './useModalVisibility';

export const Modal: React.FC<IModal> = ({ children, ariaLabel, contentClassName }) => {
  const { isVisible, onClose } = useModalVisibility();
  const modalRef = useRef<HTMLDivElement>(null);

  useKeyDownListener(onClose);

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (modalRef?.current && !modalRef?.current.contains(target)) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <FocusLock autoFocus={false}>
      <div
        role="dialog"
        aria-modal="true"
        aria-label={ariaLabel}
        className={cn(styles.modalOverlay, { [styles.modalOverlayVisible]: isVisible })}
        onClick={e => handleClickOutside(e)}
      >
        <div
          className={cn(styles.modalContent, contentClassName, {
            [styles.modalContentVisible]: isVisible,
          })}
          ref={modalRef}
        >
          {children}
          <button className={styles.modalCloseBtn} onClick={onClose} aria-label="close">
            <CloseIcon />
          </button>
        </div>
      </div>
    </FocusLock>,
    document.getElementById('modal-root') as HTMLDivElement
  );
};
